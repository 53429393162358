import React, { useState } from "react"
import GradientTitle from "./GradientTitle"
import { Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "gatsby-plugin-react-i18next"
import "./QAServicesFooter.scss"
import QAServicesForm from "./QAServicesForm"
import QAServicesSection from "./QAServicesSection"
import ReferBanner from "./ReferBanner"

function QAServicesFooter() {
  const { t } = useTranslation("qaservices", { keyPrefix: "contact" })
  const [IsFormSubmitted, setIsFormSubmitted] = React.useState(false)
  const content = (
    <>
      {!IsFormSubmitted && (
        <Col
          md={12}
          className="qa-services-form-form-section qa-services-form-support-text"
        >
          {t("We'd love to hear from you")}
        </Col>
      )}
      <Col md={6} className="qa-services-form-form-section">
        <QAServicesForm onSubmit={() => setIsFormSubmitted(true)} />
      </Col>

      <Col className="qa-services-form-banner" xs={12}>
        <ReferBanner />
      </Col>
    </>
  )

  return (
    <QAServicesSection
      className="qa-services-form"
      header={
        <GradientTitle
          topString={t("title-top")}
          bottomString={t("title-bot")}
        />
      }
      content={content}
    />
  )
}

export default QAServicesFooter
