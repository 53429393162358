import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import QAServicesAreas from "../components/services/qa/QAServicesAreas"
import QAServicesClients from "../components/services/qa/QAServicesClients"
import QAServicesFooter from "../components/services/qa/QAServicesFooter"
import ServiceNavigation from "../components/services/servicenavigation"
import QAServicesGeneralInfo from "../components/services/qa/QAServicesGeneralInfo"
import QAServicesHeader from "../components/services/qa/QAServicesHeader"
import "./mainpage.scss"
import "./qaservices.scss"
import QASpecialities from "../components/services/qa/QASpecialities"

const IndexPage = () => {
  const { t, i18n } = useTranslation("qaservices")

  return (
    <Layout headerTransparent={true} lang={i18n.resolvedLanguage}>
      <Seo
        title={t("title")}
        lang={i18n.resolvedLanguage}
        description={t("seo.description", { ns: "common" })}
      />
      {/* <div className="max-container-pages"> */}
      <Container fluid>
        <Row className="qa-services-page">
          <QAServicesHeader />

          <Col md={12} className="nav-container">
            <ServiceNavigation className=""></ServiceNavigation>
          </Col>
          <Col md={12} className="section-1">
            <QAServicesGeneralInfo />
          </Col>
          <Col md={12} className="section-2">
            <div className="section-2-container">
              <QAServicesAreas />
            </div>
          </Col>
          <Col md={12} className="section-3">
            <div className="section-3-container">
              <QASpecialities />
            </div>
          </Col>

          <Col md={12} className="section-4">
            <QAServicesClients />
          </Col>
          <Col id="contactUs" md={12} className="section-5">
            <div className="section-5-container">
              <QAServicesFooter />
            </div>
          </Col>
        </Row>
      </Container>
      {/* </div> */}
    </Layout>
  )
}

export default IndexPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "qaservices"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
