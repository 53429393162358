import React from "react"
import GradientTitle from "./GradientTitle"
import { useTranslation } from "gatsby-plugin-react-i18next"
import QAServicesSection from "./QAServicesSection"
import "./QASpecialities.scss"
import QAOfferingBoxAreas from "./QAOfferingBoxAreas"
import QASpecialitiesBox from "./QASpecialityBox"
import { StaticImage } from "gatsby-plugin-image"

export default function QASpecialities() {
  const { t } = useTranslation("qaservices", { keyPrefix: "specialities" })
  const content = (
    <div className="specialities-container">
      <div className="specialities">
        <div id="accessibility">
          <QASpecialitiesBox
            title={t("accessibility-title")}
            content={t("accessibility")}
            buttonText={t("Read More")}
            modalContent={t("accessibility-modal")}
            svgComponent={
              <StaticImage
                src="../../../images/icons/qa-accessibility.svg"
                alt="qa Accessibility"
                width={50}
                formats={["auto", "webp", "avif"]}
              />
            }
          />
        </div>
        <div id="performance">
          <QASpecialitiesBox
            title={t("performance-title")}
            content={t("performance")}
            buttonText={t("Read More")}
            modalContent={t("performance-modal")}
            svgComponent={
              <StaticImage
                src="../../../images/icons/qa-performance.svg"
                alt="qa Performance"
                width={30}
                formats={["auto", "webp", "avif"]}
              />
            }
          />
        </div>
      </div>
    </div>
  )
  return (
    <QAServicesSection
      className="qa-specialities"
      header={
        <GradientTitle
          topString={t("title-top")}
          bottomString={t("title-bot")}
        />
      }
      content={content}
    />
  )
}
