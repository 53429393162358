import { StaticImage } from 'gatsby-plugin-image';

import * as React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import xhr from '../../../lib/xhr';
import SocialMedia from '../../layout/socialmedia';

import './QAServicesForm.scss';
import { Link } from 'gatsby';

type props = {
  onSubmit?: () => void;
};

export default ({ onSubmit }: props) => {
  const { t } = useTranslation('qaservices', { keyPrefix: 'form' });
  const GATSBY_CONTACT_ENDPOINT = process.env.GATSBY_CONTACT_ENDPOINT;

  /** Send Form logic */
  const [status, setStatus] = React.useState('initial');
  const form = React.useRef(null);

  const isInitial = status === 'initial' || status === 'sending';
  const isError = status === 'error';
  const isSuccess = status === 'success';

  const handleSubmit = (event) => {
    event.preventDefault();
    if (onSubmit) onSubmit();
    setStatus('sending');

    if (!form.current) return;
    const formData = new FormData(form.current);
    const body: any = {};
    const formValues: any = {};

    // Validate only the required fields
    for (let element of Array.from((form.current as HTMLFormElement).elements)) {
      const inputElement = element as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

      if (
        (inputElement.required && inputElement.type === 'text') ||
        inputElement.tagName === 'TEXTAREA'
      ) {
        const value = formData.get(inputElement.name) as string;
        if (!value || value.trim().length < 1) {
          setStatus('initial');
          return;
        }
      }
    }

    for (let [k, v] of formData) {
      if (k !== 'file') {
        formValues[k] = v;
      }
    }

    body.title = 'Refer a friend Form';
    body.type = 'refer';
    body.name = formValues.name;
    body.email = formValues.email;

    const formValuesList = Object.keys(formValues).map((keyName, i) => (
      <li key={i}>
        <b>{keyName}: </b>
        {formValues[keyName]}
      </li>
    ));
    const msg = <ul>{formValuesList}</ul>;
    body.msg = ReactDOMServer.renderToStaticMarkup(msg);

    xhr(GATSBY_CONTACT_ENDPOINT, body)
      .then(() => setStatus('success'))
      .catch(() => setStatus('error'));

    return false;
  };

  function cleanStatus(e) {
    e.preventDefault();
    setStatus('initial');
  }

  const qaForm = (
    <section className="section-qa-form">
      <Row md={12}>
        <Form method="POST" ref={form} onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md={6} controlId="name">
              <Col className="form-group" md={12}>
                <Form.Label>{t('First Name')} *</Form.Label>

                <Form.Control type="text" required name="name" />
              </Col>
            </Form.Group>

            <Form.Group as={Col} md={6} controlId="lastname">
              <Col className="form-group" md={12}>
                <Form.Label>{t('Last Name')} *</Form.Label>
                <Form.Control type="text" required name="lastname" />
              </Col>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md={12} className="form-group" controlId="email">
              <Form.Label>{t('Email')} *</Form.Label>
              <Form.Control
                type="email"
                required
                name="email"
                placeholder={t('Placeholder email')}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md={12} controlId="message">
              <Form.Label>{t('Message')} *</Form.Label>
              <Form.Control
                type="text"
                required
                name="message"
                placeholder={t('Placeholder message')}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md={12} id="checkbox">
              <Form.Check
                label={
                  <>
                    {t('You agree to our friendly')}
                    <Link className="privacy-policy-link" to="/privacy-policy">
                      {t('privacy policy')}
                    </Link>{' '}
                    *
                  </>
                }
                required
                type="checkbox"
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 qa-form-button-container">
            <Col md={12} className="form-group">
              <Button
                variant="primary"
                className="btn-success qa-service-form-button"
                type="submit"
              >
                {t('Send message')}
              </Button>
              {/* <button
                onClick={() => {
                  setStatus("error")
                  if (onSubmit) onSubmit()
                }}
              >
                Error
              </button>
              <button
                onClick={() => {
                  setStatus("success")
                  if (onSubmit) onSubmit()
                }}
              >
                Success
              </button> */}
            </Col>
          </Row>
        </Form>
      </Row>
    </section>
  );

  const mailSuccess = (
    <div className="thank-you">
      <h2>{t('Mail success title')}</h2>
      <h3>{t('Mail success message')}</h3>
    </div>
  );

  const mailError = (
    <>
      <div className="thank-you">
        <h2>{t('Mail error title')}</h2>
        <h4>{t('Mail error message')}</h4>
      </div>
    </>
  );

  return (
    <section className="qa-services-form-wrapper">
      {isInitial && qaForm}
      {isSuccess && mailSuccess}
      {isError && mailError}
    </section>
  );
};
