import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./QAServicesSection.scss"

type props = {
  header: React.ReactNode
  content: React.ReactNode
  className?: string
}

export default ({ header, content, className }: props) => {
  return (
    <section className={`qa-services-section ${className ? className : ""}`}>
      <Container fluid>
        <Row className="container">
          <Col className="header">{header}</Col>
          <Col className="content">{content}</Col>
        </Row>
      </Container>
    </section>
  )
}
