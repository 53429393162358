import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import { Button } from "react-bootstrap"
import "./QAOfferingBoxAreas.scss"
import QAServicesModal from "./QAServicesModal"

type props = {
  title: string
  content: string
  buttonText: string
  modalContent: React.ReactNode
}

function QAOfferingBoxAreas({
  title,
  content,
  buttonText,
  modalContent,
}: props) {
  const { language } = useI18next()
  const { t } = useTranslation("qaservices", { keyPrefix: "areas" })
  const [showModal, setShowModal] = React.useState(false)

  const isSpanish = language.toUpperCase() === "ES"
  const containerClassName = `box-areas-container ${isSpanish ? "spanish" : ""}`

  const ariaLabelValue = `${buttonText} ${t("about")} ${title}`

  return (
    <div className={containerClassName}>
      <div className="box-area-top-section">
        <h1 className="box-areas-title">{title}</h1>
        <p className="box-areas-content">{content}</p>
      </div>
      {/* <div className="box-area-bottom-section box-areas-read-more"> */}
      <Button
        aria-label={ariaLabelValue}
        className="box-area-bottom-section box-areas-read-more"
        onClick={() => {
          setShowModal(true)
        }}
      >
        {buttonText}
      </Button>

      {/* SEO-friendly hidden content */}
      <div className="sr-only">
        <h2>{title}</h2>
        {modalContent}
      </div>

      <QAServicesModal
        title={title}
        content={modalContent}
        setShow={setShowModal}
        show={showModal}
      />
    </div>
  )
}

export default QAOfferingBoxAreas
