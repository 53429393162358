import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Button } from "react-bootstrap"
import "./ReferBanner.scss"

export default () => {
  const { t } = useTranslation("qaservices", { keyPrefix: "refer" })

  return (
    <div className="refer-banner-container">
      <h1 className="refer-banner-heading">{t("Do you know")}</h1>
      <h2 className="refer-banner-support-text">{t("We welcome")}</h2>
      <Link to="/refer-a-friend/#referNow">
        <Button className="refer-banner-button">{t("Refer Now")}</Button>
      </Link>
    </div>
  )
}
