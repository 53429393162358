import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./QAServicesGeneralInfo.scss"
import GradientTitle from "./GradientTitle"
import QAServicesSection from "./QAServicesSection"

export default () => {
  const { t } = useTranslation("qaservices", { keyPrefix: "description" })
  return (
    <QAServicesSection
      className="qa-services-general-info"
      header={
        <GradientTitle
          topString={t("title-top")}
          bottomString={t("title-bot")}
        />
      }
      content={
        <div className="qa-services-general-info-description">
          <p>{t("Great quality")}</p>
          <br />
          <p>{t("Holistic approach")}</p>
        </div>
      }
    />
  )
}
