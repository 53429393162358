import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./QAServicesAreas.scss"
import GradientTitle from "./GradientTitle"
import QAOfferingBoxAreas from "./QAOfferingBoxAreas"
import QAServicesSection from "./QAServicesSection"

export default function QAServicesAreas() {
  const { t } = useTranslation("qaservices", { keyPrefix: "areas" })

  const content = (
    <div className="areas-container">
      <div className="areas">
        <QAOfferingBoxAreas
          title={t("QA Outsourcing")}
          content={t("We offer manual and automation testing")}
          buttonText={t("Read More")}
          modalContent={t("Outsourcing QA services offers companies")}
        />

        <QAOfferingBoxAreas
          title={t("Audit Services")}
          content={t("Our experts can conduct")}
          buttonText={t("Read More")}
          modalContent={t("Audit Team")}
        />
        <QAOfferingBoxAreas
          title={t("Process enhancement")}
          content={t("We follow the Shift Left approach")}
          buttonText={t("Read More")}
          modalContent={t("Enhance your QA processes")}
        />

        <QAOfferingBoxAreas
          title={t("Test Framework Development")}
          content={t("We specialize in creating")}
          buttonText={t("Read More")}
          modalContent={t("Tool Evaluation and Framework Development")}
        />
      </div>
    </div>
  )

  return (
    <QAServicesSection
      className="qa-services-areas"
      header={
        <GradientTitle
          topString={t("title-top")}
          bottomString={t("title-bot")}
        />
      }
      content={content}
    />
  )
}
