import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import { Button } from "react-bootstrap"
import QAServicesModal from "./QAServicesModal"
import "./QASpecialityBox.scss"

type props = {
  title: string
  content: string
  buttonText: string
  svgComponent: React.ReactNode
  modalContent: React.ReactNode
}

function QASpecialitiesBox({
  title,
  content,
  buttonText,
  modalContent,
  svgComponent,
}: props) {
  const [showModal, setShowModal] = React.useState(false)
  const { t } = useTranslation()
  const ariaLabel = `${buttonText} ${t('about')} ${title}`
  return (
    <div className="box-speciality-container">
      <div className="rectangle">
        <div className="qa-speciality-svg-container">{svgComponent}</div>
      </div>
      <div className="box-speciality-top-section">
        <h1 className="box-speciality-title">{title}</h1>
        <p className="box-speciality-content">{content}</p>
      </div>
      {/* <div className="box-area-bottom-section box-areas-read-more"> */}
      <div className="box-button-footer-container">
        <Button
          className="box-speciality-bottom-section box-speciality-read-more"
          aria-label={ariaLabel}
          onClick={() => {
            setShowModal(true)
          }}
        >
          {buttonText}
        </Button>
      </div>

      {/* SEO-friendly hidden content */}
      <div className="sr-only">
        <h2>{title}</h2>
        {modalContent}
      </div>

      <QAServicesModal
        title={title}
        content={modalContent}
        setShow={setShowModal}
        show={showModal}
      />
    </div>
  )
}

export default QASpecialitiesBox
