import React from "react"
import "./GradientTitle.scss"

type props = {
  topString: string
  bottomString: string
}

function GradientTitle({ topString, bottomString }: props) {
  return (
    <>
      <h1 className="gradient-title gradient-title-top">{topString}</h1>
      <h1 className="gradient-title gradient-title-bottom">{bottomString}</h1>
    </>
  )
}

export default GradientTitle
